$(document).ready(function() {

  $('#send_form').on('click', function (e) {
        e.preventDefault();
        var name = $('#name').val();
        var email = $('#email').val();
        console.log(name, email);
        $.ajax({

            type: "POST",
            data: {name: name, email: email},
            success: function( response ) {
              console.log(response);
               setTimeout(function() {
                    $('#subscribeToArticles').modal('hide');
                }, 1000);
              if(response.status== 'success'){
                $('#success-div').show();
                 $('#success-div').text(response.message);
                $('#error-div').hide();
                $('#form_subscribe').hide(); 
                
                 
              }
                
                 if(response.status== 'error'){
                  $('#success-div').show();
                  $('#error-div').hide();
                  $('#success-div').text('Subscribed');
              
                 }
                
            },
           
                
        });
    });
   
});
  
