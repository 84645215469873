$(document).ready(function(){
	function isEmpty(obj){
		if($(obj).val().trim()==''){
			return true;
		} else {
			return false;
		}
	}
	if($("#business_form").length>0){
		$("#business_form").submit(function(ev){
			ev.preventDefault();
			var valid=true;
			$("#business_form input").each(function(){
				$(this).parent("div").removeClass("has-error");  
			});
			if(isEmpty($("#business_form [name=name]"))){
				$("#business_form [name=name]").parent("div").addClass("has-error"); valid = false;
			}
			if(isEmpty($("#business_form [name=email]"))){
				$("#business_form [name=email]").parent("div").addClass("has-error"); valid = false;
			}
			if(isEmpty($("#business_form [name=phone]"))){
				$("#business_form [name=phone]").parent("div").addClass("has-error"); valid = false;
			}
			if(valid){
				$.ajax({
					url:'/business/contact',
					type:'POST',
					data: $("#business_form").serialize(),
					fail : function(){
						alert('OOPS! something went wrong. We are trying hard to fix it for you.');
					},
					error: function(){
						alert('OOPS! something went wrong. We are trying hard to fix it for you.');
					},
					success: function(){
						$("#business_form").html("<div class='alert alert-success'>Contact request submitted. Will get back to you shortly.<br>If its urgent please dial us on the number given above.</div>");
					}
				});
			}

		});
	}
});